@import "../../utils/mixins.scss";

.analytics-header {
  display: flex !important;
  // flex-direction: row;
  // justify-content: center;
  align-items: center !important;
  text-align: center !important;
}
.header {
  .pickerIcon {
    display: flex;
    //flex-direction: row;
    align-items: center;
    cursor: pointer;
    //padding: 0px 0px 10px;
  }
  .datepicker {
    width: 100%;
    height: 30px;
  }
  .month-picker {
    width: 3rem !important;
    display: inline-flex;
  }
  .registerBtn {
    margin-right: 1rem;
  }

  .transition-on-display {
    transition: height 300ms linear, opacity 500ms ease-in-out;
    overflow: hidden;
  }
  &__apps {
    display: block;
  }
  &__logo {
    display: none;
  }
  width: 100%;
  background-color: var(--ds_header_color);
  position: sticky;
  top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 70px;
  // box-shadow: 0px 6px 26px 0px #efeff7;
  z-index: 2;
  &__image {
    width: 250px;
    height: 80px;
    min-width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: #16449b;
    &__image-cover {
      width: 169px;
      height: 60px;
      object-fit: contain;
    }
  }
  &__flex {
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    height: 80px;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    &__left-side {
      display: flex;
    }
    &__item-spacing {
      margin-left: 20px;
    }
  }
  &__flex-buttons {
    // flex: 55%;
    flex-grow: 0;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-shrink: 0;
    order: 1;
    padding-left: calc(2rem - 10px);
    &__margin {
      margin-left: 1rem;
    }
    &__button {
      cursor: pointer;
      @include general-button;
      background-color: white;
      color: var(--primary-theme);
      border: 1px solid var(--primary-theme);
      font-weight: 500;
      margin: 5px 5px;
      &:hover:enabled {
        color: white;
        background-color: var(--secondary-theme);
        border: 1px solid var(--secondary-theme);
      }
    }
  }
  &__notification-corner {
    flex: 20%;
    overflow: hidden;
    order: 2;
    // margin-left: 170px;
    margin-right: 3%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    &__image {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 44px;
      &__image-cover {
        cursor: pointer;
        object-fit: cover;
        // margin: 0rem 0.5rem;
      }
    }

    &__profile-logo {
      position: relative;
      flex-shrink: 0;
      cursor: pointer;
      margin-left: 4%;
      width: 44px;
      height: 44px;
      border-radius: 22px;
      border: 1px solid #16449b;
      // overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      &__cover {
        width: 60%;
        height: 60%;
        object-fit: cover;
        z-index: -1;
      }
      &__online {
        position: absolute;
        margin-top: 30px;
        margin-right: 32px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #39dd84;
        z-index: +50;
      }
    }
  }
  &__logout-icon {
    margin-left: 10px;
    cursor: pointer;
  }
  a {
    text-decoration: none;
  }
}

@media screen and (max-width: 580px) {
  .header {
    &__logo {
      min-height: 25px !important;
    }
    &__image {
      margin-left: 7px;
      width: 50px !important;
      background-color: white;
      &__image-cover {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }
    }
    &__apps {
      display: none;
    }
    &__notification-corner {
      &__image {
        height: 30px;

        &__image-cover {
          width: 30px;
          height: 30px;
          margin: 0px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .hide-button {
    display: none;
  }

  .header {
    width: 100%;
    flex-grow: 1;
    justify-content: space-between;
    &__image {
      width: 196px;
      min-width: 0px;
      background-color: white;
    }
    &__flex-buttons {
      padding: 0px;
      &__margin {
        margin-left: 0px;
      }
    }

    &__flex {
      flex-grow: 1;
      // width:100%;
    }
    &__logo {
      display: block;
      order: 4;
      width: 40px;
      min-height: 30px;
      margin-left: 10px;
      margin-right: 10px;
      overflow: hidden;
    }
    &__flex-buttons {
      order: 3;
      &__button {
        display: none;
      }
    }
    &__notification-corner {
      &__image {
        &__image-cover {
          margin: 0px;
        }
      }
      margin-right: 5px;
      &__profile-logo {
        display: none;
        &__online {
          display: none;
        }
      }
    }
  }
}
.dropdown-pre-icon {
  margin-right: 10px;
  vertical-align: middle;
  color: gray;
  padding: 0px 0px 10px;
}
//   cursor: pointer;
//   background-color: white;
//   height: 48px;
//   padding: 0px 20px;
//   color: #16449b;
//   border: 2px solid #16449b;
//   border-radius: 25px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 18px;
//   font-weight:600;
//   margin-left: 40px;
//   &:hover {
//     color: white;
//     background-color: #16449b;
//     border: 2px solid white;
//   }
// }
// a.active {
//   cursor: pointer;
//   height: 48px;
//   padding: 0px 20px;
//   border-radius: 25px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 18px;
//   font-weight:600;
//   margin-left: 40px;
//   color: white;
//   background-color: #16449b;
//   border: 2px solid white;
// }
