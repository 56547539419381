.rdt_TableHead {
  border: solid 1px #e9edf0;
}
.rdt_TableCol {
  color: #868686 !important;
  font-size: 14px !important;
}
.rdt_TableCell {
  font-size: 14px;
  color: #000000;
  // padding-top: 20px;
  // padding-bottom: 20px;
}
.supervisorDatatable .rdt_TableCell,
.teamDatatable .rdt_TableCell {
  padding-top: 20px;
  padding-bottom: 20px;
}
.searchWord {
  margin: 20px;
}
.searchWord .MuiInputBase-inputTypeSearch {
  padding: 10px 15px;
}
.searchWord .MuiInputLabel-outlined {
  transform: translate(15px, 12px) scale(0.98);
}
.searchWord .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75);
}
.searchWord .input__box-container {
  margin-bottom: 0px;
}
.searchWord .MuiOutlinedInput-root {
  width: 300px;
  max-width: 100%;
}
.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  //margin-right: 20px;
}
.rdt_TableHeader {
  display: none !important;
}
