:root {
  --primary-theme: #3c7bf6;
  --secondary-theme: #1e52b9;
  --ds_side_panel_color: #3c7bf6;
  --ds_side_tab_active_color: white;
  --ds_panel_heading_color: white;
  --ds_header_color: white;
  --ds_graph_fill_color: white;
  --ds_graph_label_color: white;
  --rating_color: black;
  --white-color: white;
  --black-color: #000;
  --page-bg-color: #ffffff;
  --border-color: #dcdcdc;
}
:export {
  primarytheme: --primary-theme;
}
