@import "../../utils/mixins.scss";

.EditProfile {
  width: 100%;
  &__page-title {
    padding: 13px 20px;
    background-color: #eff2f7;
    padding-left: 40px;
  }
  &__profile-picture-container {
    & img {
      border-radius: 100px;
      width: 166px;
      margin-left: 120px;
      max-width: 166px;
      max-height: 166px;
      min-height: 166px;
      min-width: 166px;
      margin-bottom: 15px;
    }
    &__upload-input {
      display: none;
      margin-left: 120px;
    }
    &__camera {
      position: absolute;
      margin-left: 120px;
      width: 166px;
      height: 166px;
      background: #484848;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--white-color);
      opacity: 0.6;
    }
  }
  &__transportIcons-container {
    &__transport-icon {
      border: 2px solid #edeeef;
      border-radius: 4px;
      // padding: 45px 30px;
      // width: 40px;
      // height: 35px;
      padding: 21px;
      width: 56px;
      height: 56px;
      margin-bottom: 10px;
      position: relative;
      &__selected {
        border: 2px solid var(--primary-theme);
      }
    }
    &__transport-icon:not(:first-child) {
      margin-left: 10px;
    }
  }
  &__chips {
    &__tagInputField {
      margin-top: 0px;
      margin-bottom: 10px;
    }
  }
  // &__dropdown {
  //   .MuiInputLabel-outlined {
  //     background-color: var(--page-bg-color) !important;
  //   }
  // }
}
