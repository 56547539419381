.autocomplete-location {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 1;
  // margin-bottom: 5px;
  z-index: 401;
  &__loading-gif {
    width: 25px;
    height: 25px;
  }
  &__target-value {
    margin-left: 2px;
    margin-right: 2px;
    font-size: 14px;
    word-break: keep-all;
    padding-bottom: 2px;
  }

  &__container {
    min-width: 100%;
    min-width: 100%;
    max-height: 100%;
    min-height: 100%;
    // overflow-y: hidden;
  }

  &__field {
    display: flex;
    // border: 1px solid #e7e6e9;
    // height: 75px;
    width: 100%;
    border-radius: 5px;
    // background-color: $color-white;
    // background-color: white;
    .input {
      width: 100%;
    }
    &__plain-input {
      border: none;
      margin-top: 4px;
      font-size: 14px;
      // border-bottom: 1px solid #fff;
      width: calc(100% - 40px);
      background: none;
      color: #fff;
      outline: none;
    }
  }

  &__show-map-icon {
    width: 1.5vw;
    margin-left: 10px;
    margin-right: 10px;
    align-self: start;
  }
  &__errormsg {
    margin-top: 2px;
    font-size: 12px;
    color: red;
  }

  &__input {
    padding: 0.7rem;
    font-size: 1rem;
    width: 100%;
    outline: none;
    border-style: none;
    min-width: 30px;
    margin-left: 1.5vw;
    z-index: 10;
  }

  &__box {
    list-style: none;
    // background-color: $color-white;
    background-color: white;
    padding-left: 0;
    margin-top: 0;
    position: absolute;
    width: 100%;
    top: 100%;
    z-index: 400;

    li {
      display: flex;
      align-items: center;
      padding: 0.4rem;
      cursor: pointer;

      color: black;
      text-align: left;
      cursor: pointer;
      &:hover {
        background-color: rgb(242, 242, 242);
      }
      img {
        height: 16px;
        width: 16px;
        margin: 0.5rem;
        min-height: 16px;
        min-width: 16px;
      }
    }
    .active {
      background-color: rgb(242, 242, 242);
    }
  }

  &__btn {
    display: flex;
    background-color: transparent;
    // color: $header_element_color;
    color: white;
    outline: none;
    align-items: center;
    // border: 0.5px solid $color-white;
    border: 0.5px solid white;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 10px;
    cursor: pointer;
  }

  @media screen and (max-width: 527px) {
    &__target-value {
      display: none;
    }
  }
}
.autocomplete-location__field__plain-input::placeholder {
  color: #fff;
}

@media only screen and (max-width: 375px) {
  .autocomplete-location {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    z-index: 1;
    // margin-bottom: 5px;
    z-index: 401;

    &__field {
      display: flex;
      // border: 1px solid #e7e6e9;
      // height: 75px;
      //width: 242px;
      width: 100%;
      border-radius: 5px;
      // background-color: $color-white;
      // background-color: white;
    }
  }
}
