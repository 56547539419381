@import "../../utils/mixins.scss";
.phone {
  position: relative;
  // margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
  &__input {
    @include para1;
    height: 51px;
    border: 1px solid #ccc;
    width: 100%;
    padding-left: 2%;
    // color: #7c7799;
    background: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: 400;
    &:hover:enabled {
      border-color: black;
    }
    &:focus {
      border-color: #3c7bf6;
      outline-offset: 0;
      outline: 0;
      box-shadow: none;
      border-width: 2px;
    }
    &::placeholder {
      opacity: 0.8;
      font-size: medium;
      color: #505050;
      // color: #7c7799;
    }
  }
  &__input-error {
    height: 46px;
    border: 1px solid #d9534f;
    width: 100%;
    padding-left: 2%;
    @include para1;
    // color: #7c7799;
    // border-top-right-radius: 10px;
    // border-bottom-right-radius: 10px;
    font-weight: 400;

    &:focus {
      border-color: #d9534f;
      outline-offset: 0;
      outline: 0;
      box-shadow: none;
    }
    &::placeholder {
      opacity: 0.8;
      // color: #7c7799;
    }
  }
  &__input-wrapper {
    display: flex;
    width: 100%;
  }
  &__dropdown-btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 15px 23px 15px 23px;
    cursor: pointer;
    outline: none;
    border: 1px solid #ccc;
    border-right: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &__errormsg {
    // font-size: 14px;
    // color: red;
    // /* padding: 2px; */
    // margin-bottom: 2px;
    // display: flex;
    font-size: 14px;
    color: red;
    text-align: left;
    padding: 2px;
    font-weight: 400;
  }

  &__cross {
    position: absolute;
    right: 0.75rem;
    align-self: center;
    z-index: 1;
  }

  &__title {
    display: flex;
    max-width: 100%;
    font-weight: 500;
    @include heading5;
    font-weight: 600;
  }

  &__wrapper {
    display: flex;
    position: relative;
  }
  // &__btn {
  //   border-style: none;
  //   background-color: white;
  //   cursor: pointer;
  //   &:focus {
  //     outline: none;
  //   }
  // }

  &__dropdown {
    position: absolute;
    background-color: #f9f9f9;
    width: 300px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 411;
    overflow-y: auto;
    max-height: 200px;
    margin-left: 10px;
    margin-top: 60px;
    overflow-x: hidden;
    padding-inline-start: 0;
  }

  &__country-content {
    position: relative;
    color: #000;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #ddd;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    text-align: start;
  }
  &__country-content:hover {
    cursor: pointer;
    background-color: lightgrey;
  }

  &__country-content:first-child {
    // border-top-left-radius: 4px;
    // border-top-right-radius: 4px;
    border-top: none;
  }
  &__country-name {
    margin-left: 6px;
  }
  &__country-code {
    color: #999;
    margin-left: 10px;
  }
}

.active {
  background-color: lightgrey;
}

::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}
::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  cursor: pointer;
}
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

.flag {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 11px;
  // margin: 8px;
  background: url("../../assets/images/flags.png") no-repeat;
  cursor: pointer;
}
.flag.flag-gu {
  background-position: -96px -55px;
}
.flag.flag-mn {
  background-position: -208px -88px;
}
.flag.flag-va {
  background-position: -48px -154px;
}
.flag.flag-tibet {
  background-position: -32px -143px;
}
.flag.flag-fo {
  background-position: -64px -44px;
}
.flag.flag-th {
  background-position: -16px -143px;
}
.flag.flag-tr {
  background-position: -144px -143px;
}
.flag.flag-tl {
  background-position: -80px -143px;
}
.flag.flag-kz {
  background-position: -144px -77px;
}
.flag.flag-zm {
  background-position: -16px -165px;
}
.flag.flag-uz {
  background-position: -32px -154px;
}
.flag.flag-dk {
  background-position: -64px -33px;
}
.flag.flag-scotland {
  background-position: -176px -121px;
}
.flag.flag-gi {
  background-position: -224px -44px;
}
.flag.flag-gy {
  background-position: -128px -55px;
}
.flag.flag-bj {
  background-position: -112px -11px;
}
.flag.flag-fr {
  background-position: -80px -44px;
}
.flag.flag-mo {
  background-position: -224px -88px;
}
.flag.flag-ir {
  background-position: -112px -66px;
}
.flag.flag-io {
  background-position: -80px -66px;
}
.flag.flag-tm {
  background-position: -96px -143px;
}
.flag.flag-ch {
  background-position: -96px -22px;
}
.flag.flag-mt {
  background-position: -32px -99px;
}
.flag.flag-nl {
  background-position: -240px -99px;
}
.flag.flag-gp {
  background-position: -16px -55px;
}
.flag.flag-im {
  background-position: -48px -66px;
}
.flag.flag-tv {
  background-position: -176px -143px;
}
.flag.flag-mu {
  background-position: -48px -99px;
}
.flag.flag-pe {
  background-position: -96px -110px;
}
.flag.flag-vi {
  background-position: -112px -154px;
}
.flag.flag-hn {
  background-position: -176px -55px;
}
.flag.flag-ss {
  background-position: -128px -132px;
}
.flag.flag-ae {
  background-position: -16px 0;
}
.flag.flag-td {
  background-position: -240px -132px;
}
.flag.flag-pw {
  background-position: 0 -121px;
}
.flag.flag-nu {
  background-position: -32px -110px;
}
.flag.flag-bt {
  background-position: -208px -11px;
}
.flag.flag-ms {
  background-position: -16px -99px;
}
.flag.flag-cv {
  background-position: -240px -22px;
}
.flag.flag-es {
  background-position: -224px -33px;
}
.flag.flag-mh {
  background-position: -144px -88px;
}
.flag.flag-la {
  background-position: -160px -77px;
}
.flag.flag-vn {
  background-position: -128px -154px;
}
.flag.flag-py {
  background-position: -16px -121px;
}
.flag.flag-br {
  background-position: -176px -11px;
}
.flag.flag-ye {
  background-position: -224px -154px;
}
.flag.flag-ie {
  background-position: 0 -66px;
}
.flag.flag-gh {
  background-position: -208px -44px;
}
.flag.flag-cg {
  background-position: -80px -22px;
}
.flag.flag-cu {
  background-position: -224px -22px;
}
.flag.flag-hu {
  background-position: -224px -55px;
}
.flag.flag-sg {
  background-position: -224px -121px;
}
.flag.flag-at {
  background-position: -176px 0;
}
.flag.flag-lk {
  background-position: -224px -77px;
}
.flag.flag-vu {
  background-position: -144px -154px;
}
.flag.flag-bo {
  background-position: -160px -11px;
}
.flag.flag-jo {
  background-position: -208px -66px;
}
.flag.flag-er {
  background-position: -208px -33px;
}
.flag.flag-za {
  background-position: -256px -154px;
}
.flag.flag-rs {
  background-position: -80px -121px;
}
.flag.flag-nr {
  background-position: -16px -110px;
}
.flag.flag-ls {
  background-position: -256px -77px;
}
.flag.flag-jm {
  background-position: -192px -66px;
}
.flag.flag-tz {
  background-position: -208px -143px;
}
.flag.flag-ki {
  background-position: -16px -77px;
}
.flag.flag-sj {
  background-position: 0 -132px;
}
.flag.flag-cz {
  background-position: -16px -33px;
}
.flag.flag-pg {
  background-position: -128px -110px;
}
.flag.flag-lv {
  background-position: -32px -88px;
}
.flag.flag-do {
  background-position: -96px -33px;
}
.flag.flag-lu {
  background-position: -16px -88px;
}
.flag.flag-no {
  background-position: -256px -99px;
}
.flag.flag-kw {
  background-position: -112px -77px;
}
.flag.flag-mx {
  background-position: -96px -99px;
}
.flag.flag-yt {
  background-position: -240px -154px;
}
.flag.flag-ly {
  background-position: -48px -88px;
}
.flag.flag-cy {
  background-position: 0 -33px;
}
.flag.flag-ph {
  background-position: -144px -110px;
}
.flag.flag-my {
  background-position: -112px -99px;
}
.flag.flag-sm {
  background-position: -48px -132px;
}
.flag.flag-et {
  background-position: -240px -33px;
}
.flag.flag-ru {
  background-position: -96px -121px;
}
.flag.flag-tj {
  background-position: -48px -143px;
}
.flag.flag-ai {
  background-position: -64px 0;
}
.flag.flag-pl {
  background-position: -176px -110px;
}
.flag.flag-kp {
  background-position: -64px -77px;
}
.flag.flag-uy {
  background-position: -16px -154px;
}
.flag.flag-gb {
  background-position: -112px -44px;
}
.flag.flag-gs {
  background-position: -64px -55px;
}
.flag.flag-kurdistan {
  background-position: -96px -77px;
}
.flag.flag-rw {
  background-position: -112px -121px;
}
.flag.flag-ec {
  background-position: -128px -33px;
}
.flag.flag-mm {
  background-position: -192px -88px;
}
.flag.flag-pa {
  background-position: -80px -110px;
}
.flag.flag-wales {
  background-position: -160px -154px;
}
.flag.flag-kg {
  background-position: -256px -66px;
}
.flag.flag-ve {
  background-position: -80px -154px;
}
.flag.flag-tk {
  background-position: -64px -143px;
}
.flag.flag-ca {
  background-position: -16px -22px;
}
.flag.flag-is {
  background-position: -128px -66px;
}
.flag.flag-ke {
  background-position: -240px -66px;
}
.flag.flag-ro {
  background-position: -64px -121px;
}
.flag.flag-gq {
  background-position: -32px -55px;
}
.flag.flag-pt {
  background-position: -256px -110px;
}
.flag.flag-tf {
  background-position: -256px -132px;
}
.flag.flag-ad {
  background-position: 0 0;
}
.flag.flag-sk {
  background-position: -16px -132px;
}
.flag.flag-pm {
  background-position: -192px -110px;
}
.flag.flag-om {
  background-position: -64px -110px;
}
.flag.flag-an {
  background-position: -112px 0;
}
.flag.flag-ws {
  background-position: -192px -154px;
}
.flag.flag-sh {
  background-position: -240px -121px;
}
.flag.flag-mp {
  background-position: -240px -88px;
}
.flag.flag-gt {
  background-position: -80px -55px;
}
.flag.flag-cf {
  background-position: -64px -22px;
}
.flag.flag-zanzibar {
  background-position: 0 -165px;
}
.flag.flag-mw {
  background-position: -80px -99px;
}
.flag.flag-catalonia {
  background-position: -32px -22px;
}
.flag.flag-ug {
  background-position: -240px -143px;
}
.flag.flag-je {
  background-position: -176px -66px;
}
.flag.flag-km {
  background-position: -32px -77px;
}
.flag.flag-in {
  background-position: -64px -66px;
}
.flag.flag-bf {
  background-position: -48px -11px;
}
.flag.flag-mc {
  background-position: -80px -88px;
}
.flag.flag-sy {
  background-position: -192px -132px;
}
.flag.flag-sn {
  background-position: -64px -132px;
}
.flag.flag-kr {
  background-position: -80px -77px;
}
.flag.flag-eu {
  background-position: -256px -33px;
}
.flag.flag-bn {
  background-position: -144px -11px;
}
.flag.flag-st {
  background-position: -144px -132px;
}
.flag.flag-england {
  background-position: -192px -33px;
}
.flag.flag-lc {
  background-position: -192px -77px;
}
.flag.flag-dm {
  background-position: -80px -33px;
}
.flag.flag-be {
  background-position: -32px -11px;
}
.flag.flag-ni {
  background-position: -224px -99px;
}
.flag.flag-ua {
  background-position: -224px -143px;
}
.flag.flag-mz {
  background-position: -128px -99px;
}
.flag.flag-pf {
  background-position: -112px -110px;
}
.flag.flag-tn {
  background-position: -112px -143px;
}
.flag.flag-ee {
  background-position: -144px -33px;
}
.flag.flag-xk {
  background-position: -208px -154px;
}
.flag.flag-sx {
  background-position: -176px -132px;
}
.flag.flag-sd {
  background-position: -192px -121px;
}
.flag.flag-gd {
  background-position: -128px -44px;
}
.flag.flag-ci {
  background-position: -112px -22px;
}
.flag.flag-sz {
  background-position: -208px -132px;
}
.flag.flag-cl {
  background-position: -144px -22px;
}
.flag.flag-fi {
  background-position: 0 -44px;
}
.flag.flag-ga {
  background-position: -96px -44px;
}
.flag.flag-jp {
  background-position: -224px -66px;
}
.flag.flag-de {
  background-position: -32px -33px;
}
.flag.flag-np {
  background-position: 0 -110px;
}
.flag.flag-re {
  background-position: -48px -121px;
}
.flag.flag-bg {
  background-position: -64px -11px;
}
.flag.flag-sc {
  background-position: -160px -121px;
}
.flag.flag-ng {
  background-position: -208px -99px;
}
.flag.flag-qa {
  background-position: -32px -121px;
}
.flag.flag-mk {
  background-position: -160px -88px;
}
.flag.flag-aw {
  background-position: -208px 0;
}
.flag.flag-kn {
  background-position: -48px -77px;
}
.flag.flag-al {
  background-position: -80px 0;
}
.flag.flag-bw {
  background-position: -240px -11px;
}
.flag.flag-um {
  background-position: -256px -143px;
}
.flag.flag-ky {
  background-position: -128px -77px;
}
.flag.flag-tt {
  background-position: -160px -143px;
}
.flag.flag-so {
  background-position: -80px -132px;
}
.flag.flag-lt {
  background-position: 0 -88px;
}
.flag.flag-by {
  background-position: -256px -11px;
}
.flag.flag-bb {
  background-position: 0 -11px;
}
.flag.flag-us {
  background-position: 0 -154px;
}
.flag.flag-md {
  background-position: -96px -88px;
}
.flag.flag-ag {
  background-position: -48px 0;
}
.flag.flag-hm {
  background-position: -160px -55px;
}
.flag.flag-as {
  background-position: -160px 0;
}
.flag.flag-eg {
  background-position: -160px -33px;
}
.flag.flag-sv {
  background-position: -160px -132px;
}
.flag.flag-sl {
  background-position: -32px -132px;
}
.flag.flag-fk {
  background-position: -32px -44px;
}
.flag.flag-am {
  background-position: -96px 0;
}
.flag.flag-ck {
  background-position: -128px -22px;
}
.flag.flag-tw {
  background-position: -192px -143px;
}
.flag.flag-kh {
  background-position: 0 -77px;
}
.flag.flag-to {
  background-position: -128px -143px;
}
.flag.flag-se {
  background-position: -208px -121px;
}
.flag.flag-cd {
  background-position: -48px -22px;
}
.flag.flag-pn {
  background-position: -208px -110px;
}
.flag.flag-gr {
  background-position: -48px -55px;
}
.flag.flag-id {
  background-position: -256px -55px;
}
.flag.flag-vc {
  background-position: -64px -154px;
}
.flag.flag-somaliland {
  background-position: -96px -132px;
}
.flag.flag-bi {
  background-position: -96px -11px;
}
.flag.flag-pk {
  background-position: -160px -110px;
}
.flag.flag-pr {
  background-position: -224px -110px;
}
.flag.flag-bd {
  background-position: -16px -11px;
}
.flag.flag-co {
  background-position: -192px -22px;
}
.flag.flag-fm {
  background-position: -48px -44px;
}
.flag.flag-bm {
  background-position: -128px -11px;
}
.flag.flag-ar {
  background-position: -144px 0;
}
.flag.flag-bv {
  background-position: -224px -11px;
}
.flag.flag-sb {
  background-position: -144px -121px;
}
.flag.flag-mq {
  background-position: -256px -88px;
}
.flag.flag-eh {
  background-position: -176px -33px;
}
.flag.flag-bh {
  background-position: -80px -11px;
}
.flag.flag-it {
  background-position: -144px -66px;
}
.flag.flag-hr {
  background-position: -192px -55px;
}
.flag.flag-sa {
  background-position: -128px -121px;
}
.flag.flag-mv {
  background-position: -64px -99px;
}
.flag.flag-mg {
  background-position: -128px -88px;
}
.flag.flag-dz {
  background-position: -112px -33px;
}
.flag.flag-gg {
  background-position: -192px -44px;
}
.flag.flag-gm {
  background-position: -256px -44px;
}
.flag.flag-af {
  background-position: -32px 0;
}
.flag.flag-li {
  background-position: -208px -77px;
}
.flag.flag-sr {
  background-position: -112px -132px;
}
.flag.flag-vg {
  background-position: -96px -154px;
}
.flag.flag-cr {
  background-position: -208px -22px;
}
.flag.flag-tc {
  background-position: -224px -132px;
}
.flag.flag-ao {
  background-position: -128px 0;
}
.flag.flag-ma {
  background-position: -64px -88px;
}
.flag.flag-mr {
  background-position: 0 -99px;
}
.flag.flag-gn {
  background-position: 0 -55px;
}
.flag.flag-ne {
  background-position: -176px -99px;
}
.flag.flag-nf {
  background-position: -192px -99px;
}
.flag.flag-wf {
  background-position: -176px -154px;
}
.flag.flag-hk {
  background-position: -144px -55px;
}
.flag.flag-gf {
  background-position: -160px -44px;
}
.flag.flag-ps {
  background-position: -240px -110px;
}
.flag.flag-ic {
  background-position: -240px -55px;
}
.flag.flag-cw {
  background-position: -256px -22px;
}
.flag.flag-ml {
  background-position: -176px -88px;
}
.flag.flag-ax {
  background-position: -224px 0;
}
.flag.flag-gl {
  background-position: -240px -44px;
}
.flag.flag-dj {
  background-position: -48px -33px;
}
.flag.flag-cn {
  background-position: -176px -22px;
}
.flag.flag-ht {
  background-position: -208px -55px;
}
.flag.flag-lr {
  background-position: -240px -77px;
}
.flag.flag-tg {
  background-position: 0 -143px;
}
.flag.flag-ba {
  background-position: -256px 0;
}
.flag.flag-ge {
  background-position: -144px -44px;
}
.flag.flag-bz {
  background-position: 0 -22px;
}
.flag.flag-au {
  background-position: -192px 0;
}
.flag.flag-iq {
  background-position: -96px -66px;
}
.flag.flag-cm {
  background-position: -160px -22px;
}
.flag.flag-gw {
  background-position: -112px -55px;
}
.flag.flag-az {
  background-position: -240px 0;
}
.flag.flag-na {
  background-position: -144px -99px;
}
.flag.flag-fj {
  background-position: -16px -44px;
}
.flag.flag-zw {
  background-position: -32px -165px;
}
.flag.flag-bs {
  background-position: -192px -11px;
}
.flag.flag-il {
  background-position: -16px -66px;
}
.flag.flag-nz {
  background-position: -48px -110px;
}
.flag.flag-me {
  background-position: -112px -88px;
}
.flag.flag-si {
  background-position: -256px -121px;
}
.flag.flag-nc {
  background-position: -160px -99px;
}
.flag.flag-lb {
  background-position: -176px -77px;
}

@media only screen and (max-width: 375px) {
  .phone {
    position: relative;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    &__input {
      @include para1;
      height: 51px;
      border: 1px solid #ccc;
      // width: 171px;
      width: 100%;
      padding-left: 2%;
      // color: #7c7799;
      background: none;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      font-weight: 400;
      &:hover:enabled {
        border-color: black;
      }
      &:focus {
        border-color: #3c7bf6;
        outline-offset: 0;
        outline: 0;
        box-shadow: none;
        border-width: 2px;
      }
      &::placeholder {
        opacity: 0.8;
        font-size: medium;
        color: #505050;
        // color: #7c7799;
      }
    }
  }
}
