@import "../../utils/mixins.scss";

.Profile {
  width: 100%;
  margin-left: 5%;
  margin-top: 2%;
  &__page-heading {
    font-size: 22px;
    font-weight: bold;
    margin: 1rem 0;
  }
  &__page-content {
    &__changeInfo {
      margin: 1.5rem 0;
    }
    &__container-margin {
      margin-top: 20px;
    }
  }
  &__container {
    width: 50%;
  }
  &__picture-container {
    & img {
      border-radius: 100px;
      width: 166px;
      // margin-left: 120px;
      max-width: 166px;
      max-height: 166px;
      min-height: 166px;
      min-width: 166px;
      margin-bottom: 15px;
    }
    &__camera {
      position: absolute;
      // margin-left: 120px;
      width: 166px;
      height: 166px;
      background: #484848;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--white-color);
      opacity: 0.6;
    }
    &__upload {
      display: none;
      margin-left: 120px;
    }
  }
}
